<template>
  <div class="container" style="min-height: 85vh !important;">
    <div class="container">
      <b-row class="justify-content-center">
        <b-row class="align-items-center">
          <b-col>
            <b-img
              v-show="!isMobile"
              src="img/brand/googleturtle.svg"
              class="turtle"
              width="400"
              height="320"
            />
          </b-col>
          <b-col>
            <h1 class="title">
              Carl-bot
              <br />Dashboard
            </h1>
            <div class></div>
            <p v-if="this.stats.guilds != 0" style="color:#fefefe;font-size:22px" class>
              Serving
              <b class="smalltitle">{{stats.members}}</b> members
              <br />In
              <b class="smalltitle">{{stats.guilds}}</b> servers
            </p>
            <p v-else>
              &#8203; &#x200b;
              <br />&#8203; &#x200b;
            </p>

            <b-button block to="/servers" v-if="logged_in" pill class="btn-lg mainbutton">
              <font-awesome-icon icon="cog" class="mr-2" />Manage
            </b-button>
            <b-button block v-on:click="login" v-else pill class="btn-lg mainbutton">
              <font-awesome-icon :icon="['fab', 'discord']" class="mx-2" />Log in with Discord
            </b-button>
          </b-col>
        </b-row>
      </b-row>
    </div>
    <b-row class="justify-content-center" style="margin-top:125px">
      <h1 class="subtitle" style="margin-top: 55px;">Why Carl-bot?</h1>
    </b-row>

    <hr />
    <div>
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Reaction Roles
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/roles?id=reaction-roles"
            >[docs]</a>
          </h2>
          <ul>
            <li>High limits (250 roles)</li>
            <li>Different modes: unique, verify, reversed, binding, temporary and more</li>
            <li>Multiple roles with a single reaction (plays nicely with unique)</li>
            <li>
              <a href="https://i.imgur.com/2A4EFbK.mp4" style="color:#fff">Fast</a>, rate limits spammers to prevent abuse
            </li>
            <li>Use any emoji, even ones the bot doesn't have access to</li>
            <li>Generate neat embeds without having to type out each emoji and role</li>
            <li>Self-destructing messages</li>
            <li>Blacklist and whitelist roles to decide who can pick up what role</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_rr.png" height="300" />
        </div>
      </b-row>

      <hr />
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Log everything
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/logging"
            >[docs]</a>
          </h2>
          <ul>
            <li>Message events: Deleted messages, edited messages, purged messages</li>
            <li>Discord invite links</li>
            <li>Member updates: Roles, name changes, avatar changes, bans, unbans</li>
            <li>Members joining and leaving</li>
            <li>Updates to the server: Channels, roles, and emojis</li>
            <li>Ignore channels, members and prefixes (useful for spammy bots)</li>
            <li>Split up the logging into separate channels to reduce clutter without losing information</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_logging.png" />
        </div>
      </b-row>
      <hr />
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Powerful moderation
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/moderation"
            >[docs]</a>
          </h2>
          <ul>
            <li>Modlogs lets your mods take responsibility for their mod actions</li>
            <li>All the mod commands you could ever want, timed or not</li>
            <li>"Drama channel" that gives your mods an easy overview of the rules being broken</li>
            <li>Sticky roles to prevent people from leaving to get rid of their mute</li>
            <li>Highlight new members joining</li>
            <li>Manage roles in bulk</li>
            <li>Show past infractions from members with the reason and responsible moderator</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_modlog.png" />
        </div>
      </b-row>
      <hr />
       <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Carl Gaming
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/games"
            >[docs]</a>
          </h2>
          <ul>
            <li>Get notified whenever there is a free game giveaway.</li>
            <li>Get notifications for discounts and offers on games</li>
            <li>Configure discount threshold for notifications</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_gaming.png" />
        </div>
      </b-row>
      <hr />
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Suggestions
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/suggestions"
            >[docs]</a>
          </h2>
          <ul>
            <li>Let users suggest things and have everyone vote on it</li>
            <li>Send a message to the user who made the suggestion when a decision is made</li>
            <li>Keep a separate log channel with suggestions with a mod response</li>
            <li>Anonymous mode to help combat bias</li>
            <li>Restrict suggestions to a specific channel</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_suggestion.png" />
        </div>
      </b-row>
      <hr />
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Granular permission system
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/config?id=managing-commands"
            >[docs]</a>
          </h2>
          <ul>
            <li>Give each command its own set of rules</li>
            <li>Manage commands in bulk on the website</li>
            <li>Add global rules</li>
            <li>Redirect output, mute output, keep spammy commands in a designated bot-channel</li>
            <li>Use the bot to its full potential without ever annoying anyone</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_perms.png" />
        </div>
      </b-row>
      <hr />
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Automod
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/automod"
            >[docs]</a>
          </h2>
          <ul>
            <li>Punish bad links, spam, attachmentspam, mentionspam, bad words</li>
            <li>Select your punishments per-rule being broken</li>
            <li>Delete scary file formats</li>
            <li>Mark channels as 'media-only' to delete any messages without a link or attachment</li>
            <li>Whitelist certain roles and channels to ignore rules being broken in them</li>
            <li>Custom rate limits for a lot of these rules</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_automod.png" />
        </div>
      </b-row>
      <hr />
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Powerful custom commands
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/tagstriggers?id=advanced-usage"
            >[docs]</a>
          </h2>
          <ul>
            <li>Variables, including things like channel topic, membercount, and author id</li>
            <li>Random lists, optionally select your own seeding</li>
            <li>
              <a href="https://carl.gg/t/41017">Powerful enough to create Conway's game of life</a>
            </li>
            <li>Share your creations both with the website and with other creative and talented people in the support server</li>
            <li>Execute commands, mutate their behavior and override default settings</li>
            <li>Comes with embeds!</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_tags.png" height="350" />
        </div>
      </b-row>
      <hr />
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">
            Welcome messages
            <a
              style="color:#777;font-size:15px"
              href="https://docs.carl.gg/#/welcome"
            >[docs]</a>
          </h2>
          <ul>
            <li>Send welcome messages, farewell messages and ban messages</li>
            <li>Send a dm upon joining that is separate from the normal welcome message</li>
            <li>Supports embeds and variables!</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_welcome.png" height="350" />
        </div>
      </b-row>
      <hr />
      <b-row class="justify-content-between">
        <div>
          <h2 class="subtitle">User engagement</h2>
          <ul>
            <li>It has a starboard, everyone loves a good starboard</li>
            <li>Send a message every x minutes and optionally ping a role</li>
            <li>Fast twitch notifications</li>
            <li>Mention roles without leaving them mentionable with feeds</li>
            <li>Postcount tracking</li>
            <li>Cats, dogs and various cute animals</li>
            <li>User info like join date, creation date, and nickname history</li>
          </ul>
        </div>

        <div>
          <img src="img/frontpage_starboard.png" height="350" />
        </div>
      </b-row>
    </div>
    <hr />
    <div class="d-flex flex-column" style="margin-top:20px;">
      <footer class="footer">
        <b-container>
          <b-row>
            <b-col>
              <h6 class="tinytitle">Carl.gg</h6>A service provided by
              <a href="https://botlabs.gg">BotLabs.gg</a> | All Rights Reserved
            </b-col>
            <b-col>
              <div class="float-right">
                <b-link to="/terms-of-use">
                  <b class="footer_title">Terms of Use</b>
                </b-link>
                <br />

                <a href="https://botlabs.gg/privacy-policy">
                  <b class="footer_title">Privacy Policy</b>
                </a>
                <br />

                <a href="https://botlabs.gg/copyright-dispute-policy">
                  <b class="footer_title">Copyright Dispute Policy</b>
                </a>
                <br />

                <a href="https://botlabs.gg/gdpr-privacy-notice">
                  <b class="footer_title">GDPR Privacy Notice</b>
                </a>
                <br />

                <a href="https://botlabs.gg/cookie-policy">
                  <b class="footer_title">Cookie Policy</b>
                </a>
                <br />
                <a href="https://botlabs.gg/end-user-license-agreement">
                  <b class="footer_title">End User License Agreement</b>
                </a>
                <br />
              </div>
            </b-col>
          </b-row>
        </b-container>
      </footer>
    </div>
  </div>
</template>


<script>
export default {
  name: "goaway",
  components: {},
  data() {
    return {
      guilds: 0,
      members: 0,
      channels: 0,
      num: 0,
      ws: null,
      channels: {},
      roles: {},
    };
  },
  methods: {
    login() {
      window.location.href = process.env.VUE_APP_DOMAIN + "/api/login";
    },
    intToHex(int) {
      return (int || 0).toString(16);
    },
    updateAuthInfo(e) {
      if (e.data.discriminator != undefined) {
        this.$store.commit("login", e.data);
      }
      window.removeEventListener("message", this.updateAuthInfo);

      this.$router.push("/servers");
    },
  },
  computed: {
    logged_in() {
      return Object.keys(this.$store.state.discord_user).length !== 0;
    },
    roleList() {
      return Object.values(this.roles).sort((a, b) => b.position - a.position);
    },
    channelList() {
      return Object.values(this.channels).sort(
        (a, b) => b.position - a.position
      );
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    bragging() {
      if (this.guilds == 0) {
        return ["\u200b", "\u200b"];
      }
      return [`Serving ${this.members} members`, `in ${this.guilds} servers.`];
    },

    stats() {
      return this.$store.state.stats;
    },
  },
  beforeCreate: function () {
    this.axios.get("/api/v1/botstats").then((r) => {
      // this.members = r.data.members;
      // this.guilds = r.data.guilds;
      // this.channels = r.data.channels;
      if (!r.data.logged_in) {
        this.$store.commit("logout");
      }
      this.$store.commit("sync_stats", r.data);
    });
  },
};
</script>

<style>
.mainbutton {
  background-color: #0000 !important;
  border-color: #eee !important;
  border-width: 3px !important;
}

.mainbutton:hover {
  background-color: #eeeeee !important;
  border-color: #00000000 !important;
  border-width: 3px !important;
  color: #2715a7;
}
@media screen and (max-width: 1080px) {
  .turtle {
    display: none !important;
  }
}

.footer_title {
  padding-right: 4px;
  color: #ccc;
}
.footer_title:hover {
  color: #9e80ff;
  text-decoration: underline;
  text-decoration-color: #9e80ff;
}
</style>
